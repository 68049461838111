<section class="sitemap">
  <div class="box">
    <div class="box-wrapper">
      <ng-container *ngFor="let column of contentColumns">
        <div class="box-wrapper__element">
          <ng-container *ngFor="let section of column.sections">
            <div *ngIf="section.heading" class="element-heading">
              <span>{{ section.heading | translate }}</span>
            </div>
            <div class="element-detail">
              <ng-container *ngFor="let item of section.link">
                <div class="element-detail__content" [ngClass]="{ 'is-heading': !section.heading }">
                  <a
                    *ngIf="!item.isExternalLink"
                    class="element-detail__content--layout"
                    rel="noreferrer noopener"
                    target="_blank"
                    [href]="[translate.currentLang === 'th' ? item.path : translate.currentLang + item.path]"
                  >
                    <span>{{ item.label | translate }}</span>
                  </a>
                  <a
                    *ngIf="item.isExternalLink"
                    class="element-detail__content--layout"
                    rel="noreferrer noopener"
                    target="_blank"
                    [href]="item.path"
                  >
                    <span>{{ item.label | translate }}</span>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</section>
