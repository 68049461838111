import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '../../../services/squidex.service'
import { Observable, of, tap } from 'rxjs'
import { SquidexCacheService } from '../../../services/squidex-cache.service'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
@Component({
  selector: 'flowaccount-faq-accordion-squidex',
  templateUrl: './faq-accordion-squidex.component.html',
  styleUrls: ['./faq-accordion-squidex.component.scss'],
})
export class FaqAccordionSquidexComponent implements OnInit {
  @Input() FAQHeader: string
  @Input() contentSquidex: string
  @Input() contentScully: string
  public serviceList$: Observable<object[]>
  public headingDefault = this.translate.currentLang === 'th' ? 'คำถามที่พบบ่อย (FAQ)' : 'Frequently Asked Questions'
  private faqQuestions: number
  constructor(
    public translate: TranslateService,
    private squidexCacheService: SquidexCacheService,
    public readonly squidexService: SquidexService,
    private transferState: TransferStateService,
  ) {}

  ngOnInit(): void {
    if (isScullyGenerated()) {
      this.serviceList$ = this.transferState.useScullyTransferState<object[]>(this.contentScully, of([]))
    } else {
      this.serviceList$ = this.squidexCacheService
        .getServiceList$(
          this.contentScully,
          this.contentSquidex,
          this.squidexService.getContentSquidexApiOrder.bind(this.squidexService),
        )
        .pipe(
          tap((data) => {
            this.transferState.setState(this.contentScully, data)
          }),
        )
    }
  }

  onClickToggleFAQ(index: number) {
    if (this.faqQuestions === index) {
      this.faqQuestions = null
    } else {
      this.faqQuestions = index
    }
  }
  checkCurrentFaqTab(index: number) {
    return this.faqQuestions === index
  }
}
