import { Component, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  public _year: number = new Date().getFullYear()
  public contactDetails = [
    {
      type: 'CUSTOMET_SERVICE',
      phone: '020268989',
      email: 'support@flowaccount.com',
      workingHours: ['NEW_CONTACT.CCS_WORKING_HOUR', 'NEW_CONTACT.MON-FRI', 'NEW_CONTACT.CS_HOLIDAY_HOUR'],
    },
    {
      type: 'SALES',
      phone: '020268991',
      email: 'demo@flowaccount.com',
      workingHours: ['NEW_CONTACT.CCS_WORKING_HOUR', 'NEW_CONTACT.CS_WORKING_HOUR'],
      demoDetail: 'NEW_CONTACT.CONTACT_SALES_DETAIL',
    },
  ]
  constructor(public translate: TranslateService) {}
}
