import { Inject, Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { TrafficSourceService } from './traffic-source.service'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Subscription } from 'rxjs'
import { IdleState } from '../enum/landing-enum'
import { environment } from '../../environments/environment'
import { GROWTHBOOK_CONFIG, GrowthBookConfig, GrowthBookService } from '@flowaccount/landing'
import { Keepalive } from '@ng-idle/keepalive'
import { TranslateService } from '@ngx-translate/core'
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root',
})
export class GrowthBookAttributeService {
  private readonly SESSION_TIMEOUT = 30 * 60 * 1000 // 30 minutes in ms
  private readonly MAX_SESSION_DURATION = 24 * 60 * 60 * 1000 // 24 hours in ms
  private readonly TIME_CHECK_SESSION = 60 * 1000
  private readonly lastActivityTimeName = 'last_activity_time'
  private readonly sessionStartTimeName = 'session_start_time'
  private idleState = IdleState.NotStarted
  private lastActivityTime: number = Date.now()
  private sessionStartTime: number = Date.now()
  private keepalive$: Subscription
  private routerEvent: Subscription
  private currentUrl: string
  private previousUrl: string
  private utmSource = ''
  private utmMedium = ''
  private utmCampaign = ''
  private utmTerm = ''
  private utmContent = ''
  private growthBookInitialized = false
  private growthBookInitializedSubject = new BehaviorSubject<boolean>(false)

  constructor(
    @Inject(GROWTHBOOK_CONFIG) private growthBookConfig: GrowthBookConfig,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private readonly cookieService: CookieService,
    private readonly deviceService: DeviceDetectorService,
    private readonly trafficSourceService: TrafficSourceService,
    private readonly growthBookService: GrowthBookService,
    private readonly translate: TranslateService,
  ) {
    this.watch()
    this.setUtmParameters()
  }

  getBaseAttributes(): Record<string, any> {
    return {
      id: this.getUserId(),
      utmId: '',
      utmSource: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmTerm: this.utmTerm,
      utmContent: this.utmContent,
      userTrafficSource: this.trafficSourceService.getTrafficSource(),
      userType: this.isReturningVisitorType(),
      userAgent: navigator?.userAgent ?? '',
      userOS: this.deviceService.os,
      userOsVersion: this.deviceService.os_version,
      userBrowser: this.deviceService.browser,
      userDeviceType: this.deviceService.deviceType,
      userOrientationType: this.deviceService.orientation,
      userLanguage: this.translate.currentLang,
      userCountry: '',
      userTimezone: '',
      userCity: '',
      userRegion: '',
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      userResolution: window.innerWidth + 'x' + window.innerHeight,
      userSubscriptionStatus: this.cookieService.get('SubscriptionStatus') || 'New',
      userVisitDate: this.getVisitDate(),
      userVisitTime: this.getVisitTime(),
      userDayOfWeek: this.getDayOfWeek(),
      previousUrl: this.previousUrl,
      currentUrl: this.currentUrl,
      referralUrl: document?.referrer ?? '',
    }
  }

  getUserId(): string {
    const userId = this.cookieService.get('userId')
    if (!userId) {
      const uuid = uuidv4()
      const DAY_IN_MS = 24 * 60 * 60 * 1000
      const expirationDate = new Date(new Date().getTime() + 30 * DAY_IN_MS)
      this.cookieService.set('userId', uuid, {
        expires: expirationDate,
        domain: environment.domain,
      })
      return uuid
    }
    return userId
  }

  isReturningVisitorType() {
    return this.cookieService.get('fa_visit_bf') ? 'Returning Visitor' : 'New Visitor'
  }

  getVisitDate(): string {
    try {
      return new Date()?.toISOString()?.split('T')[0] || ''
    } catch (err) {
      return ''
    }
  }

  getDayOfWeek(): string {
    const getDayByNumber = new Date().getDay()
    if (getDayByNumber < 0 || getDayByNumber > 6 || isNaN(getDayByNumber)) {
      return 'Unknown'
    }
    const DAYS = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    }
    return DAYS[getDayByNumber]
  }

  getVisitTime() {
    return new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
  }

  stop() {
    if (this.keepalive$) {
      this.keepalive$.unsubscribe()
    }
    this.idle.stop()
  }

  private watch() {
    this.loadSessionData()

    this.idle.setIdle(15) // 15 seconds of inactivity
    this.idle.setTimeout(1800) // 30 minutes to timeout
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.idle.onIdleStart.subscribe(() => {
      if (this.idleState !== IdleState.Timedout) {
        this.idleState = IdleState.Idle
      }
    })

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = IdleState.NotIdle
      this.updateActivity()
    })

    this.idle.onTimeout.subscribe(() => {
      if (this.idleState !== IdleState.Timedout) {
        this.idleState = IdleState.Timedout
        this.startNewSession()
      }
      this.idle.stop()
      this.idle.watch()
    })

    this.keepalive.interval(this.TIME_CHECK_SESSION / 1000)
    this.keepalive.onPing.subscribe(() => {
      this.checkSession()
    })

    this.keepalive.start()
    this.idle.watch()
    this.idleState = IdleState.NotStarted
  }

  private loadSessionData() {
    const lastActivityTime = this.cookieService.get(this.lastActivityTimeName)
    const sessionStartTime = this.cookieService.get(this.sessionStartTimeName)

    if (lastActivityTime) {
      this.lastActivityTime = parseInt(lastActivityTime, 10)
    }

    if (sessionStartTime) {
      this.sessionStartTime = parseInt(sessionStartTime, 10)
    }
  }

  private updateActivity() {
    this.lastActivityTime = Date.now()
    this.cookieService.set(this.lastActivityTimeName, this.lastActivityTime.toString())
  }

  private checkSession() {
    const currentTime = Date.now()
    const inactivityDuration = currentTime - this.lastActivityTime
    const sessionDuration = currentTime - this.sessionStartTime

    if (inactivityDuration > this.SESSION_TIMEOUT || sessionDuration > this.MAX_SESSION_DURATION) {
      this.startNewSession()
    }
  }

  private startNewSession() {
    this.sessionStartTime = Date.now()
    this.cookieService.set(this.sessionStartTimeName, this.sessionStartTime.toString())
    this.determineUserType()
  }

  private determineUserType() {
    const isNewUser = !this.cookieService.get('fa_visit_bf')
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)

    if (isNewUser) {
      this.cookieService.set('fa_visit_bf', 'true', {
        expires: expiryDate,
        domain: environment.domain,
      })
    }
    const growthBook = this.growthBookService.getGrowthBook()

    if (!growthBook) return

    this.growthBookService.getGrowthBook().setAttributes({
      ...this.growthBookService.getGrowthBook().getAttributes(),
      userType: this.isReturningVisitorType(),
    })
  }

  private setUtmParameters(): void {
    this.utmSource = this.trafficSourceService.getUtms().utmSource
    this.utmMedium = this.trafficSourceService.getUtms().utmMedium
    this.utmCampaign = this.trafficSourceService.getUtms().utmCampaign
    this.utmContent = this.trafficSourceService.getUtms().utmContent
    this.utmTerm = this.trafficSourceService.getUtms().utmTerm
  }
}
