<flowaccount-mobile-pos-intro></flowaccount-mobile-pos-intro>

<flowaccount-mobile-pos-cashier></flowaccount-mobile-pos-cashier>

<flowaccount-mobile-pos-sunmi></flowaccount-mobile-pos-sunmi>

<flowaccount-mobile-pos-thermal-printers></flowaccount-mobile-pos-thermal-printers>

<flowaccount-mobile-pos-app></flowaccount-mobile-pos-app>

<flowaccount-content-call-to-action-squidex
  [contentCTA]="'MOBILE_POS.CTA.HEADING'"
  [contentTextLink]="'MOBILE_POS.CTA.BTN'"
  [contentScully]="'CONTENT_CTA_MOBILE_POS'"
  [styleClass]="'mobile-pos'"
  [isMobilePOS]="true"
>
</flowaccount-content-call-to-action-squidex>

<flowaccount-blog-swiper-squidex
  [customHeader]="customHeader"
  [lang]="translate.currentLang"
  [contentSquidex]="'mobilepos-blog'"
  [contentScully]="'mobilepos-blog-key'"
></flowaccount-blog-swiper-squidex>

<flowaccount-faq-accordion-squidex
  [contentSquidex]="'mobile-pos-faq'"
  [contentScully]="'mobile-pos-faq-key'"
></flowaccount-faq-accordion-squidex>

<flowaccount-content-call-to-action-privilege
  [contentCTA_1]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_1'"
  [contentCTA_2]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_2'"
  [contentCTA_mobile]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_MOBILE'"
  [contentTextLink]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.BUTTON_LINK'"
>
</flowaccount-content-call-to-action-privilege>
