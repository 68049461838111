import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  private GOOGLE_REVIEW_CAMPAIGN = 'google_reviews'
  private GOOGLE_REVIEW_URL = 'https://g.page/r/CWd1Q4bhKOGeEAI/review'
  private subscription: Subscription = new Subscription()

  constructor(private readonly route: ActivatedRoute) {}

  redirectToGoogleReview(): void {
    if (this.hasGoogleReviewCampaign()) {
      window.location.href = this.GOOGLE_REVIEW_URL
    }
  }

  hasGoogleReviewCampaign(): boolean {
    let hasGoogleReviewCampaign = false

    const subscription = this.route.queryParams.subscribe((params) => {
      const campaign = params['utm_campaign']
      if (campaign === this.GOOGLE_REVIEW_CAMPAIGN) {
        hasGoogleReviewCampaign = true
      }
    })
    this.subscription.add(subscription)

    return hasGoogleReviewCampaign
  }

  destroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
