import { AfterViewInit, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { UserAnalyticEvents } from '../../models/event-collector.model'
import { LandingGrowthBookFeatureFlag } from '../../enum/landing-enum'
import { GrowthBookService } from '@flowaccount/landing'
import { filter, firstValueFrom, take } from 'rxjs'
import { GrowthBookFactoryService } from '../../services/growthbook-factory.service'

@Component({
  selector: 'flowaccount-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarMenuComponent implements AfterViewInit {
  @Output() trackEvent = new EventEmitter<{ dataId: string; dataName: string }>()

  public categories = [
    {
      label: 'Navigation.Products',
      class: 'products',
      items: [
        {
          heading: 'Navigation.New FlowAccount.Title',
          info: 'Navigation.New FlowAccount.Info',
          image: 'https://flowaccountcdn.com/logo_favicon/flowaccount.svg',
          route: '/',
        },
        {
          heading: 'Navigation.MobilePOS.Title',
          info: 'Navigation.MobilePOS.Info',
          image: 'https://flowaccountcdn.com/logo_favicon/flowaccount-mobile-pos.svg',
          route: '/mobile-pos',
        },
        {
          heading: 'Navigation.FlowPayroll.Title',
          info: 'Navigation.FlowPayroll.Info',
          image: 'https://flowaccountcdn.com/logo_favicon/flowaccount-payroll.svg',
          route: '/payroll',
        },
        {
          heading: 'Navigation.Autokey.Title',
          info: 'Navigation.Autokey.Info',
          image: 'https://flowaccountcdn.com/logo_favicon/flowaccount-autokey.svg',
          route: '/autokey',
        },
        { heading: 'Navigation.Review', icon: 'fas fa-solid fa-star', isSpecialLink: true, route: '/reviews' },
      ],
    },

    {
      label: 'Navigation.Functions',
      class: 'functions',
      items: [
        { heading: 'Navigation.Functions_business', icon: 'fas fa-user-tie', route: '/functions/business' },
        { heading: 'Navigation.Functions_accountant', icon: 'fas fa-calculator', route: '/functions/accounting' },

        {
          heading: 'Navigation.DOCUMENT',
          icon: 'far fa-file-alt',
          list: [
            { heading: 'Navigation.Functions_quotation', route: '/functions/quotation' },
            { heading: 'Navigation.Functions_receipt', route: '/functions/receipt' },
            {
              heading: 'Navigation.Functions_billing_note_invoice',
              route: '/functions/invoice',
            },
            {
              heading: 'Navigation.Functions_Functions_tax_invoice',
              route: '/functions/tax-invoice',
            },
          ],
        },
        { heading: 'Navigation.Functions_inventory', icon: 'fas fa-box', route: '/inventory-management' },
        { heading: 'Navigation.Functions_flowpay', icon: 'far fa-file-alt', route: '/functions/flowpay' },
        {
          heading: 'Navigation.Functions_shopee_lazada',
          image: 'https://flowaccountcdn.com/new_landing/image/icons/icon_shopping_bag.png',
          route: '/functions/e-commerce',
        },
        { heading: 'Navigation.Developer', icon: 'fas fa-code', isSpecialLink: true, route: '/developers' },
      ],
    },

    {
      label: 'Navigation.Price',
      class: 'pricing',
      route: '/pricing',
    },

    {
      label: 'Navigation.Service',
      class: 'service',
      items: [
        {
          heading: 'Navigation.BUSINESS_REGISTRATION_SUBMENU.TITLE',
          info: 'Navigation.BUSINESS_REGISTRATION_SUBMENU.DETAIL',
          icon: 'fas fa-book-open',
          route: '/business-registration',
        },
        {
          heading: 'Navigation.FIND_AN_ACCOUNTANT_SUBMENU.TITLE',
          info: 'Navigation.FIND_AN_ACCOUNTANT_SUBMENU.DETAIL',
          image: 'https://flowaccountcdn.com/new_landing/icon/user-search.svg',
          route: '/accountingfirm/businessowner',
        },
        {
          heading: 'Navigation.GOOGLE_WORKSPACE_SUBMENU.TITLE',
          info: 'Navigation.GOOGLE_WORKSPACE_SUBMENU.DETAIL',
          image: 'https://flowaccountcdn.com/new_landing/icon/google-workspace.svg',
          route: '/google-workspace',
        },
      ],
    },

    {
      label: 'Navigation.Become_out_partner_submenu',
      class: 'partner',
      items: [
        {
          heading: 'Navigation.Accounting_firm_partner',
          info: 'Navigation.Accounting_firm_partner_info',
          image: 'https://flowaccountcdn.com/new_landing/icon/firm.svg',
          route: '/accountingfirm/become-our-partner',
        },
        {
          heading: 'Navigation.FlowAccount_for_education',
          info: 'Navigation.FlowAccount_for_education_info',
          image: 'https://flowaccountcdn.com/new_landing/icon/university.svg',
          route: '/flowaccount-for-education',
        },
        {
          heading: 'Navigation.Platform_businesses_and_service',
          info: 'Navigation.Platform_businesses_and_service_info',
          image: 'https://flowaccountcdn.com/new_landing/icon/network.svg',
          route: '/our-integrations',
        },
      ],
    },

    {
      label: 'Navigation.Seminar',
      class: 'seminar',
      items: [
        {
          heading: 'Navigation.Accounting_knowledge_submenu',
          info: 'Navigation.Accounting_knowledge_submenu_info',
          image: 'https://flowaccountcdn.com/new_landing/image/icons/Book%20_idea.png',
          href: 'https://flowaccount.com/blog/',
        },
        {
          heading: 'Navigation.Seminar_SubMenu',
          info: 'Navigation.Seminar_SubMenu_info',
          icon: 'fas fa-chalkboard-teacher',
          route: '/seminars',
        },
        {
          heading: 'Navigation.Videos',
          info: 'Navigation.Videos_info',
          icon: 'fas fa-play-circle',
          href: 'https://www.youtube.com/@FlowAccountChannel/playlists',
        },
        {
          heading: 'Navigation.Ultimate',
          info: 'Navigation.Ultimate_info',
          icon: 'fas fa-book-open',
          route: '/ultimateguide',
        },
        {
          heading: 'Navigation.Faq',
          icon: 'fas fa-question-circle',
          isSpecialLink: true,
          href: 'https://flowaccount.com/faq',
        },
      ],
    },
  ]

  public isMenuOpen = false
  public openPanelIndex: number
  public openSubPanelIndex: number
  public isHeroBulletPointOn = false

  constructor(
    public translate: TranslateService,
    private router: Router,
    private growthBookService: GrowthBookService,
    private growthBookFactoryService: GrowthBookFactoryService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await firstValueFrom(
      this.growthBookFactoryService.isGrowthBookInitialized().pipe(
        filter((initialized: boolean) => initialized),
        take(1),
      ),
    )
  }

  public switchLanguage(event: Event) {
    event.preventDefault()

    const language = this.translate.currentLang === 'th' ? 'en' : 'th'
    const currentUrl = this.router.url

    const queryIndex = currentUrl.indexOf('?')
    const baseRoute = queryIndex > -1 ? currentUrl.substring(0, queryIndex) : currentUrl
    const queryParams = queryIndex > -1 ? currentUrl.substring(queryIndex) : ''

    let newRoute = ''

    if (baseRoute.includes('/en')) {
      newRoute = baseRoute.replace('/en', '')
    } else if (baseRoute === '/th' || baseRoute === '/') {
      newRoute = `/${language}`
    } else {
      newRoute = `/${language}${baseRoute}`
    }

    this.translate
      .use(language)
      .toPromise()
      .then(() => {
        this.router.navigateByUrl(decodeURIComponent(newRoute) + queryParams)
      })
  }

  getTrackEvent(dataId: string, dataName: string) {
    this.trackEvent.emit({ dataId, dataName })
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  togglePanel(index: number) {
    this.openPanelIndex = this.openPanelIndex === index ? null : index
  }

  toggleSubPanel(index: number, event: Event) {
    event.stopPropagation()
    this.openSubPanelIndex = this.openSubPanelIndex === index ? null : index
  }

  closeMenuAndPanel() {
    this.isMenuOpen = false
    this.openPanelIndex = null
    this.openSubPanelIndex = null
  }

  trackSignupEvent(value: string) {
    this.growthBookFactoryService.trackEvent(UserAnalyticEvents.Signup, value)
  }

  trackDemoEvent(value: string) {
    this.growthBookFactoryService.trackEvent(UserAnalyticEvents.DemoSchedule, value)
  }
}
