<section *ngIf="!isClosed && isCheckDevice" class="download-app-banner">
  <div class="box">
    <div class="box-close">
      <button (click)="onCloseDownloadApp()" class="box-close__marker"
        ><img
          src="https://flowaccountcdn.com/new_landing/icon/close_xmark.svg"
          class="box-close__marker--layout"
          alt="close"
      /></button>
    </div>

    <div class="box-content">
      <div class="box-content__logo">
        <img
          src="https://flowaccountcdn.com/images/cover-page/Flowaccount_logo2021.png"
          class="box-content__logo--layout"
          alt="flowaccount application"
        />
      </div>
      <div class="box-content__detail"><span [innerHTML]="'DOWNLOAD_APP_BANNER.HEADING' | translate"></span></div>
    </div>

    <div class="box-download">
      <a class="box-download__btn" [href]="onDownloadApp()" rel="noopener noreferrer" target="_blank"
        ><span>{{ 'DOWNLOAD_APP_BANNER.BUTTON' | translate }}</span></a
      >
    </div>
  </div>
</section>
