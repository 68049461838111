import { Inject, Injectable } from '@angular/core'
import { GROWTHBOOK_CONFIG, GrowthBookConfig, GrowthBookService } from '@flowaccount/landing'
import { Experiment, GrowthBook, Result } from '@growthbook/growthbook'
import { BehaviorSubject, Observable } from 'rxjs'
import { GrowthBookAttributeService } from './growthbook-attribute.service'
import { EventTrackerService } from './event-tracker.service'
import { UserAnalyticCollector, UserAnalyticEvents } from '../models/event-collector.model'
import { GrowthBookEventData } from '../models/growthbook-event-data'

@Injectable({
  providedIn: 'root',
})
export class GrowthBookFactoryService {
  private experiment: Experiment<any> = null
  private result: Result<any> = null
  private growthBookInitialized = false
  private growthBookInitializedSubject = new BehaviorSubject<boolean>(false)

  constructor(
    @Inject(GROWTHBOOK_CONFIG) private growthBookConfig: GrowthBookConfig,
    private growthBookService: GrowthBookService,
    private readonly growthBookAttributeService: GrowthBookAttributeService,
    private readonly eventTrackerService: EventTrackerService,
  ) {}

  async setupGrowthBook(): Promise<void> {
    this.growthBookService = await GrowthBookService.getInstance(this.growthBookConfig)
    await this.growthBookService.getGrowthBook().setAttributes(this.growthBookAttributeService.getBaseAttributes())
    this.growthBookInitialized = true
    this.growthBookInitializedSubject.next(true)

    this.growthBookService.getGrowthBook().setTrackingCallback((experiment, result) => {
      const userAttributes = this.growthBookService.getGrowthBook().getAttributes()
      const pageViewPayload = {
        experimentName: experiment.name,
        experimentKey: experiment.key,
        dataId: '',
        dataName: '',
        variationId: result.variationId,
        variationName: result.name,
      }

      const userEventObject = {
        ...userAttributes,
        eventId: UserAnalyticEvents.PageView,
        createdAt: new Date(),
        payLoad: pageViewPayload,
      } as UserAnalyticCollector<unknown>

      this.setExperimentData(experiment, result)

      this.eventTrackerService.send(userEventObject)
    })
  }

  trackEvent(eventId: UserAnalyticEvents, eventType: string, additionalData?: Record<string, any>): Promise<void> {
    try {
      if (!this.isGrowthBookExperiment()) return

      const eventData = GrowthBookEventData[eventId][eventType]
      const userAttributes = this.growthBookService.getGrowthBook().getAttributes()

      const trackingPayload = {
        ...eventData,
        experimentName: this.experiment.name,
        experimentKey: this.experiment.key,
        variationId: this.result.variationId,
        variationName: this.result.name,
        ...additionalData,
      }

      const userEventObject = {
        ...userAttributes,
        eventId: eventId,
        createdAt: new Date(),
        payLoad: trackingPayload,
      } as UserAnalyticCollector<unknown>

      this.eventTrackerService.send(userEventObject)
    } catch (error) {
      return
    }
  }

  isGrowthBookExperiment(): boolean {
    return this.experiment && this.result && this.result.inExperiment
  }

  setExperimentData(experiment: Experiment<any>, result: Result<any>): void {
    this.experiment = experiment
    this.result = result
  }

  getExperiment(): Experiment<any> {
    return this.experiment
  }

  getResultExperiment(): Result<any> {
    return this.result
  }

  getGrowthBook(): GrowthBook {
    return this.growthBookService.getGrowthBook()
  }

  isGrowthBookInitialized(): Observable<boolean> {
    return this.growthBookInitializedSubject.asObservable()
  }
}
