<section class="mobile-pos-sunmi">
  <div class="box-container">
    <!-- topic -->
    <div class="pos-topic">
      <!-- desktop heading  -->
      <div
        class="pos-topic__heading pos-topic__heading--desktop color-gray"
        [innerHtml]="'MOBILE_POS.SUNMI.HEADING' | translate"
      ></div>

      <!-- tablet heading  -->
      <div
        class="pos-topic__heading pos-topic__heading--tablet color-gray"
        [innerHtml]="'MOBILE_POS.SUNMI.HEADING_TABLET' | translate"
      ></div>

      <!-- mobile heading  -->
      <div
        class="pos-topic__heading pos-topic__heading--mobile color-gray"
        [innerHtml]="'MOBILE_POS.SUNMI.HEADING_MOBILE' | translate"
      ></div>

      <div class="pos-topic__heading-detail" [innerHTML]="'MOBILE_POS.SUNMI.DETAIL' | translate"></div>
    </div>

    <!-- content -->
    <div class="box">
      <!-- 1 -->
      <div class="box__item">
        <picture class="img-desktop">
          <source
            srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_s3_v3.webp"
            type="image/webp"
          />
          <img
            src="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_s3_v3.png"
            width="100%"
            loading="lazy"
            class="box__image"
            alt="ปริ้นท์ใบเสร็จผ่านมือถือ"
            title="ปริ้นท์ใบเสร็จผ่านมือถือ"
          />
        </picture>

        <div class="device-supported">
          {{ 'MOBILE_POS.SUNMI.DEVICE' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>
