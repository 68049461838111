import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '../../../services/squidex.service'
import { Observable, of, tap } from 'rxjs'
import { SwiperOptions } from 'swiper/types'
import { SquidexCacheService } from '../../../services/squidex-cache.service'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
@Component({
  selector: 'flowaccount-blog-swiper-squidex',
  templateUrl: './blog-swiper-squidex.component.html',
  styleUrls: ['./blog-swiper-squidex.component.scss'],
})
export class BlogSwiperSquidexComponent implements OnInit {
  @Input() customHeader: string
  @Input() styleClass: string
  @Input() contentSquidex: string
  @Input() contentScully: string
  public config: SwiperOptions
  public serviceList$: Observable<object[]>

  constructor(
    public translate: TranslateService,
    public readonly squidexService: SquidexService,
    private squidexCacheService: SquidexCacheService,
    private transferState: TransferStateService,
  ) {}

  ngOnInit(): void {
    if (isScullyGenerated()) {
      this.serviceList$ = this.transferState.useScullyTransferState<object[]>(this.contentScully, of([]))
    } else {
      this.serviceList$ = this.squidexCacheService
        .getServiceList$(
          this.contentScully,
          this.contentSquidex,
          this.squidexService.getContentSquidexApiOrder.bind(this.squidexService),
        )
        .pipe(
          tap((data) => {
            this.transferState.setState(this.contentScully, data)
          }),
        )
    }
    this.swiperConfig()
  }

  swiperConfig() {
    this.config = {
      observer: true,
      observeParents: true,
      speed: 700,
      keyboard: false,
      mousewheel: true,
      scrollbar: false,
      navigation: {
        nextEl: '.swiper-button-next-blog',
        prevEl: '.swiper-button-prev-blog',
      },
      breakpoints: {
        1000: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        400: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      a11y: {
        enabled: false,
      },
    }
  }

  getText(text: string) {
    return this.translate.instant('BLOG_SWIPER_TOPIC.' + text)
  }
  displayCustomText(textObject, textPosition) {
    const { heading, detail } = textObject

    switch (textPosition) {
      case 'heading':
        return this.translate.instant(heading)
        break
      case 'detail':
        return this.translate.instant(detail)
        break
    }
  }
}
