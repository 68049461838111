import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '../../../services/squidex.service'
import { SquidexCacheService } from '../../../services/squidex-cache.service'
import { Observable, of, tap } from 'rxjs'
import { SignupFlowService } from '@flowaccount/landing'
import { SignupPreviousPath } from '../../../enum/landing-enum'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
@Component({
  selector: 'flowaccount-content-call-to-action-squidex',
  templateUrl: './content-call-to-action-squidex.component.html',
  styleUrls: ['./content-call-to-action-squidex.component.scss'],
})
export class ContentCallToActionSquidexComponent implements OnInit {
  @Input() contentScully: string
  @Input() styleClass: string
  @Input() contentCTA: string
  @Input() contentCTA_1: string
  @Input() contentCTA_2: string
  @Input() contentTextLink: string
  @Input() isTotalPartners = false
  @Input() isGoogleWorkspace = false
  @Input() isPricing = false
  @Input() isMobilePOS = false
  @Input() isBusinessRegistration = false
  @Input() isRoadshow = false
  @Input() previousPath = SignupPreviousPath.FLOWACCOUNT
  public serviceList$: Observable<object[]>
  private readonly _cacheKey = 'NUMBER_OF_ACCOUNTING_FIRM_KEY'
  private readonly _squidexContentKey = 'number-of-accounting-firm'
  constructor(
    public translate: TranslateService,
    public squidexService: SquidexService,
    private squidexCacheService: SquidexCacheService,
    private signupFlowService: SignupFlowService,
    private transferState: TransferStateService,
  ) {}

  ngOnInit(): void {
    if (isScullyGenerated()) {
      this.serviceList$ = this.transferState.useScullyTransferState<object[]>(this._cacheKey, of([]))
    } else {
      this.serviceList$ = this.squidexCacheService
        .getServiceList$(
          this._cacheKey,
          this._squidexContentKey,
          this.squidexService.getContentSquidexApi.bind(this.squidexService),
        )
        .pipe(
          tap((data) => {
            this.transferState.setState(this._cacheKey, data)
          }),
        )
    }
  }

  getLink(): string {
    const lang = this.translate.currentLang
    if (this.isGoogleWorkspace) {
      return 'https://form.flowaccount.com/googleworkspace'
    } else if (this.isTotalPartners) {
      return 'https://form.flowaccount.com/request-become-partner'
    } else if (this.isPricing) {
      return '/pricing'
    } else if (this.isMobilePOS) {
      return 'https://form.flowaccount.com/demo-request-mobile-application'
    } else if (this.isBusinessRegistration) {
      return 'https://form.flowaccount.com/th-th/businessregistrationservice'
    } else if (this.isRoadshow) {
      return 'https://form.flowaccount.com/register-seminar-partners-roadshow'
    } else {
      return `https://auth.flowaccount.com/${lang}/Account/Register`
    }
  }

  onSignupEvent(): void {
    this.signupFlowService.setCookie(this.previousPath, true)
  }
}
