import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, take } from 'rxjs/operators'
import { SquidexService } from './squidex.service'

@Injectable({ providedIn: 'root' })
export class SquidexCacheService {
  private readonly cache = new Map<string, BehaviorSubject<object[]>>()

  constructor(public squidexService: SquidexService) {}

  public getServiceList$(
    cacheKey: string,
    squidexKey: string,
    fetchFunction: (squidexKey: string) => Observable<object[]>,
  ): Observable<object[]> {
    if (this.cache.has(cacheKey)) {
      const cachedSubject = this.cache.get(cacheKey)
      if (cachedSubject) {
        return cachedSubject.asObservable()
      }
    }

    const subject$ = new BehaviorSubject<object[]>([])
    this.cache.set(cacheKey, subject$)

    const cachedData = this._getCachedData(cacheKey)
    if (cachedData !== null) {
      subject$.next(cachedData)
      return subject$.asObservable()
    }

    fetchFunction(squidexKey)
      .pipe(
        take(1),
        distinctUntilChanged(),
        catchError(() => of([])),
      )
      .subscribe((data) => {
        this._saveToCache(cacheKey, data)
        subject$.next(data ?? [])
      })

    return subject$.asObservable()
  }

  private _getCachedData(cacheKey: string): object[] | null {
    try {
      return JSON.parse(localStorage.getItem(cacheKey) ?? 'null')
    } catch {
      return null
    }
  }

  private _saveToCache(cacheKey: string, data: object[]) {
    try {
      const MAX_STORAGE = 5 * 1024 * 1024
      const SAFE_LIMIT = 4.5 * 1024 * 1024

      let currentSize = JSON.stringify(localStorage).length
      const newDataSize = JSON.stringify(data).length

      while (currentSize + newDataSize > SAFE_LIMIT) {
        const oldestKey = Object.keys(localStorage)[0]
        if (!oldestKey) break
        localStorage.removeItem(oldestKey)
        currentSize = JSON.stringify(localStorage).length
      }

      if (currentSize + newDataSize > MAX_STORAGE) {
        sessionStorage.setItem(cacheKey, JSON.stringify(data))
      } else {
        localStorage.setItem(cacheKey, JSON.stringify(data))
      }
    } catch {
      return
    }
  }
}
