<div class="flowaccount-landing">
  <flowaccount-download-app-banner></flowaccount-download-app-banner>

  <flowaccount-navigation-menu></flowaccount-navigation-menu>

  <router-outlet></router-outlet>

  <footer class="advance-flow-footer">
    <flowaccount-sitemap></flowaccount-sitemap>

    <flowaccount-footer></flowaccount-footer>
  </footer>
</div>

<flowaccount-social-chat></flowaccount-social-chat>
