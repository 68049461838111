import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

declare global {
  interface Window {
    MSStream?
    opera?
  }
}

@Component({
  selector: 'flowaccount-download-app-banner',
  templateUrl: './download-app-banner.component.html',
  styleUrl: './download-app-banner.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DownloadAppBannerComponent implements OnInit {
  public isCheckDevice = false
  public isClosed = false

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.isCheckDevice = this.checkDevice()
  }

  checkDevice(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
      /huawei/i.test(userAgent)
    )
  }

  onDownloadApp() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      return 'https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th'
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'https://itunes.apple.com/th/app/new-flowaccount/id1454207520'
    } else if (/huawei/i.test(userAgent)) {
      return 'https://appgallery.huawei.com/app/C105848487'
    } else {
      return
    }
  }

  onCloseDownloadApp() {
    this.isClosed = true
  }
}
