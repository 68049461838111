import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { NotfoundComponent } from './components/notfound/notfound.component'
import { MobilePosComponent } from './components/functions/mobile-pos/mobile-pos.component'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
  },
  {
    path: 'th',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'notfound',
    component: NotfoundComponent,
  },
  {
    path: 'pricing',
    loadChildren: () => import('./components/pricing/pricing.module').then((m) => m.PricingModule),
  },
  {
    path: 'seminars',
    loadChildren: () => import('./components/seminar/seminar.module').then((m) => m.SeminarModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./components/contactus/contactus.module').then((m) => m.ContactusModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./components/aboutus/aboutus.module').then((m) => m.AboutusModule),
  },
  {
    path: 'functions',
    loadChildren: () => import('./components/functions/functions.module').then((m) => m.FunctionsModule),
  },
  {
    path: 'mobile-pos',
    component: MobilePosComponent,
  },
  {
    path: 'jobs',
    loadChildren: () => import('./components/job/job.module').then((m) => m.JobModule),
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./components/tutorial/tutorial.module').then((m) => m.TutorialModule),
  },
  {
    path: 'term-of-use',
    loadChildren: () => import('./components/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'privacy-statement',
    loadChildren: () => import('./components/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'privacy-notice',
    loadChildren: () => import('./components/privacyNotice/privacy-notice.module').then((m) => m.PrivacyNoticeModule),
  },
  {
    path: 'cookies-policy',
    loadChildren: () => import('./components/cookiesPolicy/cookies-policy.module').then((m) => m.CookiesPolicyModule),
  },
  {
    path: 'accountingfirm',
    loadChildren: () => import('./components/accountingfirm/accountingfirm.module').then((m) => m.AccountingfirmModule),
  },
  {
    path: 'upgrade-to-new-flowaccount',
    loadChildren: () => import('./components/migration/migration.module').then((m) => m.MigrationModule),
  },
  {
    path: 'developers',
    loadChildren: () => import('./components/open-api/open-api.module').then((m) => m.OpenApiModule),
  },
  {
    path: 'ultimateguide',
    loadChildren: () => import('./components/ultimateguide/ultimateguide.module').then((m) => m.UltimateGuideModule),
  },
  {
    path: 'flowaccount-for-education',
    loadChildren: () =>
      import('./components/mou-for-education/mou-for-education.module').then((m) => m.MouForEducationModule),
  },
  {
    path: 'business-registration',
    loadChildren: () =>
      import('./components/business-registration/business-registration.module').then(
        (m) => m.BusinessRegistrationModule,
      ),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./components/review/review.module').then((m) => m.ReviewModule),
  },
  {
    path: 'privilege',
    loadChildren: () =>
      import('./components/special-privileges/special-privileges.module').then((m) => m.SpecialPrivilegesModule),
  },
  {
    path: 'payroll',
    loadChildren: () => import('./components/payroll/payroll.module').then((m) => m.PayrollModule),
  },
  {
    path: 'our-integrations',
    loadChildren: () =>
      import('./components/platform-businesses/platform-businesses.module').then((m) => m.PlatformBusinessesModule),
  },
  {
    path: 'autokey',
    loadChildren: () => import('./components/autokey/autokey.module').then((m) => m.AutokeyModule),
  },
  {
    path: 'seminars-login',
    loadChildren: () => import('./components/demo-tracking/demo-tracking.module').then((m) => m.DemoTrackingModule),
  },
  {
    path: 'google-workspace',
    loadChildren: () =>
      import('./components/google-workspace/google-workspace.module').then((m) => m.GoogleWorkspaceModule),
  },
  {
    path: 'accounting-firm-accelerator',
    loadChildren: () =>
      import('./components/accounting-firm-accelerator/accounting-firm-accelerator.module').then(
        (m) => m.AccountingFirmAcceleratorModule,
      ),
  },
  {
    path: 'inventory-management',
    loadChildren: () => import('./components/inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'partner-roadshow',
    loadChildren: () => import('./components/roadshow/roadshow.module').then((m) => m.RoadshowModule),
  },

  {
    path: 'en',
    loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
  },
  {
    path: 'en/pricing',
    loadChildren: () => import('./components/pricing/pricing.module').then((m) => m.PricingModule),
  },
  {
    path: 'en/seminars',
    loadChildren: () => import('./components/seminar/seminar.module').then((m) => m.SeminarModule),
  },
  {
    path: 'en/contact-us',
    loadChildren: () => import('./components/contactus/contactus.module').then((m) => m.ContactusModule),
  },
  {
    path: 'en/flowaccount-for-education',
    loadChildren: () =>
      import('./components/mou-for-education/mou-for-education.module').then((m) => m.MouForEducationModule),
  },
  {
    path: 'en/about-us',
    loadChildren: () => import('./components/aboutus/aboutus.module').then((m) => m.AboutusModule),
  },
  {
    path: 'en/functions',
    loadChildren: () => import('./components/functions/functions.module').then((m) => m.FunctionsModule),
  },
  {
    path: 'en/mobile-pos',
    component: MobilePosComponent,
  },
  {
    path: 'en/jobs',
    loadChildren: () => import('./components/job/job.module').then((m) => m.JobModule),
  },
  {
    path: 'en/tutorials',
    loadChildren: () => import('./components/tutorial/tutorial.module').then((m) => m.TutorialModule),
  },
  {
    path: 'en/reviews',
    loadChildren: () => import('./components/review/review.module').then((m) => m.ReviewModule),
  },
  {
    path: 'en/term-of-use',
    loadChildren: () => import('./components/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'en/privacy-statement',
    loadChildren: () => import('./components/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'en/privacy-notice',
    loadChildren: () => import('./components/privacyNotice/privacy-notice.module').then((m) => m.PrivacyNoticeModule),
  },
  {
    path: 'en/cookies-policy',
    loadChildren: () => import('./components/cookiesPolicy/cookies-policy.module').then((m) => m.CookiesPolicyModule),
  },
  {
    path: 'en/accountingfirm',
    loadChildren: () => import('./components/accountingfirm/accountingfirm.module').then((m) => m.AccountingfirmModule),
  },
  {
    path: 'en/upgrade-to-new-flowaccount',
    loadChildren: () => import('./components/migration/migration.module').then((m) => m.MigrationModule),
  },
  {
    path: 'en/developers',
    loadChildren: () => import('./components/open-api/open-api.module').then((m) => m.OpenApiModule),
  },
  {
    path: 'en/ultimateguide',
    loadChildren: () => import('./components/ultimateguide/ultimateguide.module').then((m) => m.UltimateGuideModule),
  },
  {
    path: 'en/business-registration',
    loadChildren: () =>
      import('./components/business-registration/business-registration.module').then(
        (m) => m.BusinessRegistrationModule,
      ),
  },
  {
    path: 'en/privilege',
    loadChildren: () =>
      import('./components/special-privileges/special-privileges.module').then((m) => m.SpecialPrivilegesModule),
  },
  {
    path: 'en/payroll',
    loadChildren: () => import('./components/payroll/payroll.module').then((m) => m.PayrollModule),
  },
  {
    path: 'en/our-integrations',
    loadChildren: () =>
      import('./components/platform-businesses/platform-businesses.module').then((m) => m.PlatformBusinessesModule),
  },
  {
    path: 'en/autokey',
    loadChildren: () => import('./components/autokey/autokey.module').then((m) => m.AutokeyModule),
  },
  {
    path: 'en/seminars-login',
    loadChildren: () => import('./components/demo-tracking/demo-tracking.module').then((m) => m.DemoTrackingModule),
  },
  {
    path: 'en/google-workspace',
    loadChildren: () =>
      import('./components/google-workspace/google-workspace.module').then((m) => m.GoogleWorkspaceModule),
  },
  {
    path: 'en/accounting-firm-accelerator',
    loadChildren: () =>
      import('./components/accounting-firm-accelerator/accounting-firm-accelerator.module').then(
        (m) => m.AccountingFirmAcceleratorModule,
      ),
  },
  {
    path: 'en/inventory-management',
    loadChildren: () => import('./components/inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'en/partner-roadshow',
    loadChildren: () => import('./components/roadshow/roadshow.module').then((m) => m.RoadshowModule),
  },

  { path: 'blog', redirectTo: '/blog', pathMatch: 'full' },
  { path: 'faq', redirectTo: '/faq', pathMatch: 'full' },
  { path: '**', redirectTo: '/notfound' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'disabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
