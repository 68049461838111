<section class="footer">
  <div class="box">
    <div class="box-line"></div>
    <div class="box-element">
      <div class="box-element__wrapper">
        <div
          *ngFor="let contact of contactDetails"
          class="box-element__wrapper--content"
          [ngClass]="contact.type.toLowerCase()"
        >
          <div class="content-header">
            <span>{{ 'NEW_CONTACT.CONTACT_' + contact.type | translate }}</span>
          </div>
          <div class="content-detail">
            <div *ngIf="contact.demoDetail" class="content-detail__demo">
              <span>{{ contact.demoDetail | translate }}</span>
            </div>
            <div class="content-detail__phone">
              <i class="fas fa-phone"></i>
              <a class="content-detail__phone--link" [href]="'tel:' + contact.phone" target="_self">
                <span>{{ contact.phone }}</span
                ><i class="fas fa-angle-right"></i>
              </a>
            </div>
            <div class="content-detail__email">
              <i class="fas fa-envelope"></i>
              <a class="content-detail__email--link" [href]="'mailto:' + contact.email" target="_top">
                <span>{{ contact.email }}</span
                ><i class="fas fa-angle-right"></i>
              </a>
            </div>
            <div class="content-detail__time">
              <i class="fas fa-clock"></i>
              <div class="content-detail__time--text">
                <span *ngFor="let hour of contact.workingHours">{{ hour | translate }}</span>
              </div>
            </div>
            <div *ngIf="contact.type === 'SALES'" class="content-detail__contact">
              <i class="fas fa-comment"></i>
              <a class="contact-link" href="/contact-us" target="_blank">
                <span>{{ 'SEND MESSAGE' | translate }}</span
                ><i class="fas fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-element">
      <div class="box-element__wrapper">
        <div class="box-element__wrapper--content social">
          <div class="content-icon">
            <div class="content-icon__app mobile">
              <div class="content-icon__app--right">
                <div class="right-social">
                  <div class="social-fb">
                    <a
                      class="social-fb__link"
                      href="https://www.facebook.com/flowaccount"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Facebook"
                    >
                      <i class="fab fa-facebook-square"></i
                    ></a>
                  </div>
                  <div class="social-yt">
                    <a
                      class="social-yt__link"
                      href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Youtube"
                    >
                      <i class="fab fa-youtube"></i
                    ></a>
                  </div>
                  <div class="social-ig">
                    <a
                      class="social-ig__link"
                      href="https://www.instagram.com/flowaccount/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Instagram"
                    >
                      <i class="fab fa-instagram"></i
                    ></a>
                  </div>
                  <div class="social-tiktok">
                    <a
                      class="social-tiktok__link"
                      href="https://www.tiktok.com/@flowaccountchannel?is_from_webapp=1&sender_device=pc"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Tiktok"
                    >
                      <img
                        src="https://flowaccountcdn.com/new_landing/icon/tiktok-brands-solid.svg"
                        alt="flowaccount tiktok"
                        loading="lazy"
                    /></a>
                  </div>
                  <div class="social-medium">
                    <a
                      class="social-medium__link"
                      href="https://medium.com/flowaccount-tech"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Medium"
                    >
                      <img
                        src="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                        alt="flowaccount medium"
                        loading="lazy"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-icon__app">
              <div class="content-icon__app--left">
                <a
                  class="left-line"
                  href="https://lin.ee/oM5V2Un"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="FlowAccount Line Official Account"
                >
                  <img
                    class="left-line__qrcode"
                    src="https://flowaccountcdn.com/images/qrcodeline.JPG"
                    alt="flowaccount qrcode"
                    loading="lazy"
                  />
                </a>
              </div>
              <div class="content-icon__app--right">
                <div class="right-application-download">
                  <div class="download-app-store">
                    <a
                      class="download-app-store__link"
                      href="https://itunes.apple.com/th/app/new-flowaccount/id1454207520"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Download FlowAccount on App Store"
                    >
                      <img
                        class="download-app-store__link--img"
                        src="https://flowaccountcdn.com/new_landing/image/logo/iOS.png"
                        alt="flowaccount ios application"
                        loading="lazy"
                    /></a>
                  </div>
                  <div class="download-google-play">
                    <a
                      class="download-google-play__link"
                      href="https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Download FlowAccount on Google Play"
                    >
                      <img
                        class="download-google-play__link--img"
                        src="https://flowaccountcdn.com/new_landing/image/logo/Android.png"
                        alt="flowaccount android application"
                        loading="lazy"
                    /></a>
                  </div>
                  <div class="downlaod-app-gallery">
                    <a
                      class="downlaod-app-gallery__link"
                      href="https://appgallery.huawei.com/app/C105848487"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Download FlowAccount on Gallery"
                    >
                      <img
                        class="downlaod-app-gallery__link--img"
                        src="https://flowaccountcdn.com/new_landing/image/logo/Huawei.png"
                        alt="flowaccount gallery application"
                        loading="lazy"
                    /></a>
                  </div>
                </div>
                <div class="right-social">
                  <div class="social-fb">
                    <a
                      class="social-fb__link"
                      href="https://www.facebook.com/flowaccount"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Facebook"
                    >
                      <i class="fab fa-facebook-square"></i
                    ></a>
                  </div>
                  <div class="social-yt">
                    <a
                      class="social-yt__link"
                      href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Youtube"
                    >
                      <i class="fab fa-youtube"></i
                    ></a>
                  </div>
                  <div class="social-ig">
                    <a
                      class="social-ig__link"
                      href="https://www.instagram.com/flowaccount/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Instagram"
                    >
                      <i class="fab fa-instagram"></i
                    ></a>
                  </div>
                  <div class="social-tiktok">
                    <a
                      class="social-tiktok__link"
                      href="https://www.tiktok.com/@flowaccountchannel?is_from_webapp=1&sender_device=pc"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Tiktok"
                    >
                      <img
                        src="https://flowaccountcdn.com/new_landing/icon/tiktok-brands-solid.svg"
                        alt="flowaccount tiktok"
                        loading="lazy"
                    /></a>
                  </div>
                  <div class="social-medium">
                    <a
                      class="social-medium__link"
                      href="https://medium.com/flowaccount-tech"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="FlowAccount Medium"
                    >
                      <img
                        src="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                        alt="flowaccount medium"
                        loading="lazy"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-icon__line">
              <a
                class="content-icon__line--add"
                href="https://lin.ee/mZPhj7W"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="FlowAccount Line"
              >
                <img
                  class="add-friend"
                  src="https://flowaccountcdn.com/new_landing/icon/line-add-friend.png"
                  alt="flowaccount line"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="box-element__wrapper--content company-address">
          <span [innerHtml]="'FlowaccountCompany' | translate"></span>
          <br />
          <span [innerHtml]="'ADDRESSDETAILS' | translate"></span><br />
          <a
            class="company-address__map"
            href="https://goo.gl/maps/FHh3jQTjovv"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="FlowAccount Map"
            [innerHtml]="'ViewMap' | translate"
          >
          </a>
        </div>
      </div>
    </div>
    <div class="box-copyright">
      <span>Copyright © {{ _year }} FlowAccount Co., Ltd. All rights reserved.</span>
    </div>
  </div>
</section>
