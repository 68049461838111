import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenSubject = new BehaviorSubject<string | null>(null)
  private isFetchingToken = false

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.invokeInternal(req, next, true)
  }

  private invokeInternal(req: HttpRequest<any>, next: HttpHandler, retry: boolean): Observable<HttpEvent<any>> {
    const excludedUrls = [environment.eventTrackerEndpointUrl]
    const isExcludedUrl = excludedUrls.some((url) => req.url.includes(url))

    if (isExcludedUrl) {
      return next.handle(req).pipe(catchError(() => of(null)))
    }

    return this.getToken(next).pipe(
      switchMap((token) => {
        if (!token) {
          return of(null)
        }

        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        })

        return next.handle(req).pipe(catchError(() => of(null)))
      }),
    )
  }

  private getToken(next: HttpHandler): Observable<string | null> {
    const cachedToken = this.getBearerToken()

    if (cachedToken) {
      return of(cachedToken)
    }

    if (this.isFetchingToken) {
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
      ) as Observable<string>
    }

    return this.fetchNewToken(next)
  }

  private fetchNewToken(next: HttpHandler): Observable<string | null> {
    this.isFetchingToken = true

    const body = `grant_type=client_credentials&scope=squidex-api&client_id=${environment.squidexAuth.clientId}&client_secret=${environment.squidexAuth.clientSecret}`

    const tokenRequest = new HttpRequest('POST', this.getUrl(), body, {
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })

    return next.handle(tokenRequest).pipe(
      filter((x) => x instanceof HttpResponse),
      map((response: HttpResponse<any>) => response.body.access_token),
      tap((token) => {
        if (token) {
          this.storeToken(token)
          this.tokenSubject.next(token)
        }
        this.isFetchingToken = false
      }),
      catchError(() => {
        this.isFetchingToken = false
        return of(null)
      }),
    )
  }

  private getBearerToken(): string | null {
    try {
      const tokenData = JSON.parse(localStorage.getItem('tokenSquidex') ?? 'null')
      if (tokenData && tokenData.token && tokenData.expires_at) {
        if (Date.now() < tokenData.expires_at - 5 * 60 * 1000) {
          return tokenData.token
        } else {
          this.clearBearerToken()
        }
      }
      return null
    } catch {
      return null
    }
  }

  private storeToken(token: string) {
    try {
      const expiresAt = Date.now() + 3600 * 1000 - 5 * 60 * 1000
      const tokenData = { token, expires_at: expiresAt }

      localStorage.setItem('tokenSquidex', JSON.stringify(tokenData))
    } catch {
      sessionStorage.setItem('tokenSquidex', token)
    }
  }

  private clearBearerToken() {
    localStorage.removeItem('tokenSquidex')
    sessionStorage.removeItem('tokenSquidex')
    this.tokenSubject.next(null)
  }

  private getUrl(): string {
    return environment.squidexAuth.url + '/identity-server/connect/token'
  }
}
