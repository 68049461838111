<section class="blog-swiper-squidex" [ngClass]="styleClass" *ngIf="(serviceList$ | async)?.length">
  <div class="box">
    <div class="box__topic">
      <ng-container *ngIf="customHeader; else defaultHeader">
        <div
          *ngIf="customHeader.heading"
          class="box__topic--title"
          [innerHTML]="displayCustomText(customHeader, 'heading')"
        ></div>
        <div
          *ngIf="customHeader.detail"
          class="box__topic--description"
          [innerHTML]="displayCustomText(customHeader, 'detail')"
        >
        </div>
      </ng-container>
      <ng-template #defaultHeader>
        <div class="box__topic--title" [innerHTML]="getText('HEADING')"></div>
        <div class="box__topic--description" [innerHTML]="getText('DETAIL')"> </div>
      </ng-template>
    </div>

    <div class="swiper-container-main">
      <swiper-container class="swiper" appSwiper [config]="config" init="false">
        <ng-container *ngFor="let item of serviceList$ | async">
          <swiper-slide class="swiper-slide">
            <a [href]="item.data.url?.iv" target="_blank" rel="noopener noreferrer">
              <picture class="box__img">
                <img
                  [src]="item.data.img?.iv"
                  class="box__img--layout"
                  [alt]="translate.currentLang === 'th' ? item.data.alt.th : item.data.alt.en"
                  loading="lazy"
                />
              </picture>

              <div class="box__content">
                <span>{{ translate.currentLang === 'th' ? item.data.titleBlog.th : item.data.titleBlog.en }}</span>
              </div>
              <div class="box__content--read-more">
                <span>{{ getText('READ_MORE') }}</span>
                <i class="fas fa-angle-right"></i>
              </div>
            </a>
          </swiper-slide>
        </ng-container>
      </swiper-container>
      <button class="swiper-button-prev-blog" aria-label="Previous slide">
        <i class="fas fa-arrow-left"></i>
      </button>
      <button class="swiper-button-next-blog" aria-label="Next slide">
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</section>
