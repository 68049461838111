import { Component, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-mobile-pos-sunmi',
  templateUrl: './mobile-pos-sunmi.component.html',
  styleUrls: ['./mobile-pos-sunmi.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobilePosSunmiComponent {
  constructor(public translate: TranslateService) {}
}
